import _ from 'lodash';
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SaveSVG from '../../../../../../../../../components/SVGs/SaveSVG';
import { AUTO_REFUND_SETTING, OVERVIEW_EDIT_TYPES } from '../../../../../../../../../constants/enums';
import recurRating from '../../../../../../../../../constants/recur_rating.json';
import callApi from '../../../../../../../../../util/apiCaller';
import { showNotification } from '../../../../../../../../../util/utility';
import { getUserId, getUserName } from '../../../../../../../../App/AppReducer';
import { fetchInvesteeDetails, updateInvesteeDetails } from '../../../../../../../AdminActions';
import { getInvesteesOrganization } from '../../../../../../../AdminReducers';
import AddNewModal from '../../../../../../ContractsPage/components/AddNewModal';

export default function OverviewEdit({
    investeeOrgId,
    setIsEditingCompany,
    escrowBalance,
    investeeDetails,
    setInvesteeDetails,
    // salesPartners,
    // capitalPartners,
    salesPartner,
    dataSpecialist,
    underwriters,
    initialIrr
}) {
    const recurInsiderStatusOptions = ["Active-noUser", "Active-User", "Inactive", "Inactive-Requested"];
    const isIRROrMaxTenurechanged = useRef(false);
    const [isCreatingEscrowAccount, setIsCreatingEscrowAccount] = useState(false);
    const dispatch = useDispatch();
    const userName = useSelector(state => getUserName(state));
    const userId = useSelector(state => getUserId(state));
    const investeeOrganization = useSelector(getInvesteesOrganization)
    const {
        is_company_active,
        trading_limit,
        irr,
        max_tenure_price,
        custom_tenure,
        custom_tenure_limit_min,
        custom_tenure_limit_max,
        fees,
        recur_fees_waived,
        fee_model,
        moratorium_months,
        mab,
        recur_rating,
        repayment_risk,
        total_contract_count,
        global_delay,
        escrow_pay_user_id,
        sales_partner_id,
        agent_id,
        underwriter_id,
        recur_insider_status,
        status,
        listing_by_priority,
        autoRefund,
    } = investeeDetails;

    const handleChange = (keyName, keyVal) => {
        if (keyName === "status" && keyVal === "Active" && !investeeDetails.irr) {
            showNotification("Error", "Status can't be active until IRR is not set");
        }
        else {
            if (keyName === "irr" || keyName === "custom_tenure_limit_max") {
                isIRROrMaxTenurechanged.current = true;
            }
            if(keyName === OVERVIEW_EDIT_TYPES.AUTO_REFUND) {
                keyVal = keyVal === AUTO_REFUND_SETTING.YES ? true : false;
            }
            const obj = { ...investeeDetails };
            obj[keyName] = keyVal;
            setInvesteeDetails(obj);
        }
    }

    const fetchDetails = () => {
        dispatch(fetchInvesteeDetails(investeeOrgId));
    }

    const createEscrowAccount = () => {
        setIsCreatingEscrowAccount(true);
        callApi(
            `repayments/investeeOrganization/${investeeOrgId}/create-escrow-pay-account`,
            "get"
        ).then(res => {
            setIsCreatingEscrowAccount(false);
            if (res.status === "Success") {
                fetchDetails();
                showNotification("Success", "Successfully created document");
            } else {
                const message = res.data?.message ? res.data?.message : "Some error occurred!";
                showNotification("Error", message);
            }
        }).catch(err => {
            setIsCreatingEscrowAccount(false);
            showNotification(err);
        });
    };

    const onCancel = () => {
        fetchDetails();
        setIsEditingCompany(false, true);
    }

    const onSave = () => {
        const obj = { ...investeeDetails };
        if (obj.status === "Active" && !obj.irr) {
            showNotification("Error", "Status can't be active until IRR is not set");
        }
        else {
            obj.last_overview_updated = {
                updated_at: new Date(),
                updated_by: userName,
                updated_by_id: userId
            };
            /* 
            deleting specific keys in obj to prevent xnpv trigger here 
            custom_tenure_limit_max
            fee_model
            fees
            irr
            */
            delete obj.custom_tenure_limit_max;
            delete obj.fee_model;
            delete obj.fees;
            delete obj.irr;
            dispatch(updateInvesteeDetails(investeeOrgId, obj, isIRROrMaxTenurechanged.current));
            setIsEditingCompany(false, true);
        }
    }

    return (
        <div>
            <div className="d-flex justify-content-between flex-wrap align-items-center company-row1 p-4">
                <div className='d-flex align-items-center'>
                    <span className="fs21 mr-4 fw600">Overview</span>
                    <div
                        className='status-tab'
                    >
                        <span className={status === "Inactive" ? 'stat bgclr5' : 'stat'}
                            onClick={() => handleChange("status", "Inactive")}
                            style={{ marginRight: "5px" }}
                        >INACTIVE</span>
                        <span className={status === "Locked" ? 'stat bgclr5' : 'stat'}
                            onClick={() => handleChange("status", "Locked")}
                        >LOCKED</span>
                        <span className={status === "Active" ? 'stat bgclr6' : 'stat'}
                            onClick={() => handleChange("status", "Active")}
                            style={{ marginRight: "5px" }}
                        >ACTIVE</span>
                    </div>
                     {/**commenting for RI Deal */}
                    {/* <div
                        className='listing-by-priority-tab'
                    >
                        <span className={listing_by_priority === true ? 'stat bgclr6' : 'stat'}
                            onClick={() => handleChange("listing_by_priority", true)}
                            style={{ marginRight: "5px" }}
                        >DEALS</span>
                        <span className={listing_by_priority === false ? 'stat bgclr5' : 'stat'}
                            onClick={() => handleChange("listing_by_priority", false)}
                            style={{ marginRight: "5px" }}
                        >MARKET</span>
                    </div> */}
                </div>
                <div className="d-flex align-items-center" >
                    <button
                        className='mr-4 btn6 btnht1 d-flex align-items-center justify-content-center'
                        onClick={onCancel}
                    >
                        <img
                            src='../../../../../assets/icon-clear.svg'
                            alt='cross'
                            className='mr-2'
                            style={{ width: '11px' }}
                        />
                        <span>Cancel</span>
                    </button>
                    <button
                        onClick={onSave}
                        className='save-btn btn5 btnht1 d-flex align-items-center justify-content-center'
                    >
                        <SaveSVG />
                        <span>Save</span>
                    </button>
                </div>
            </div>
            <div className="pl-4 pr-4 m-0" style={{ fontSize: "12px" }}>
                <div className="need-trigger">Needs Repayments,xnpv,term array trigger</div>
                <div className="need-trigger-xnpv">Needs xnpv trigger</div>
            </div>
            <div className="row p-4 m-0">
                <div className="col-md-4">
                    <div className="overview-border p-3">
                        <div className="fs12 fs500 mb-3">Company Ratings</div>
                        <div className="dflex1 p-2 br4">
                            <div className="w-50 fs10 fw300">Recur Rating</div>
                            <select
                                className='w-50 fs12'
                                value={recur_rating}
                                onChange={e => handleChange(OVERVIEW_EDIT_TYPES.RECUR_RATING, e.target.value)}
                            >
                                <option value="" disabled>Select type</option>
                                {recurRating.map((item, index) => (
                                    <option key={index} value={item}>{item}</option>
                                ))}
                            </select>
                        </div>
                        <div className="dflex1 p-2 br4 mt-3">
                            <div className="w-50 fs10 fw300">Repayment Risk</div>
                            <select
                                className='w-50 fs12'
                                value={repayment_risk ? repayment_risk : ""}
                                onChange={e => handleChange(OVERVIEW_EDIT_TYPES.REPAYMENT_RISK, e.target.value)}
                            >
                                <option value="" disabled>Select type</option>
                                <option value="High">High</option>
                                <option value="Medium">Medium</option>
                                <option value="Low">Low</option>
                                <option value="Upsell">Upsell</option>
                            </select>
                        </div>
                    </div>
                    <div className="overview-border p-3 mt-3">
                        <div className='d-flex justify-content-between align-items-center mb-3'>
                            <div className="fs12 fs500">Contracts Overview</div>
                            <button
                                data-toggle='modal'
                                data-target={initialIrr && irr && '#addnewmodal'}
                                className='btn3 fs10 d-flex align-items-center'
                                onClick={() => {
                                    if (!(initialIrr && irr)) showNotification("Error", "IRR must be set and saved before uploading!");
                                }}
                            >
                                <img
                                    src="/assets/up.svg"
                                    alt="" className='mr-2 ml-1'
                                />
                                <span className='mr-1'>Upload Contracts</span>
                            </button>
                        </div>
                        <div className="dflex1 p-2 br4">
                            <div className="w-50 fs10 fw300">Contracts Synced</div>
                            <div className="w-50 fs10 fw300 bgclr2 disable-input">{total_contract_count}</div>
                        </div>
                        <div className="dflex1 p-2 br4 mt-3">
                            <div className="w-50 fs10 fw300 need-trigger">Payment Delay (Days)</div>
                            <input
                                className='w-50'
                                type="text"
                                value={global_delay}
                                onChange={e => handleChange(OVERVIEW_EDIT_TYPES.GLOBAL_DELAY, e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="overview-border p-3 mt-3">
                        <div className="fs12 fs500 mb-3">Insiders</div>
                        <div className="dflex1 p-2 br4">
                            <div className="w-50 fs10 fw300">Insider Status</div>
                            <select
                                className='w-50 fs12'
                                value={recur_insider_status ? recur_insider_status : ""}
                                onChange={e => handleChange(OVERVIEW_EDIT_TYPES.RECUR_INSIDER_STATUS, e.target.value)}
                            >
                                <option value="" disabled>Select type</option>
                                {recurInsiderStatusOptions.map((item, index) => (
                                    <option key={index} value={item}>{item}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 pr-0">
                    <div className="overview-border p-3">
                        <div className="fs12 fs500 mb-1">Payment Setting</div>
                        <div className="dflex1 p-2 br4">
                            <div className="w-50 fs10 fw300">Auto Refund On</div>
                            <select
                                className='w-50 fs12'
                                value={autoRefund ? AUTO_REFUND_SETTING.YES : AUTO_REFUND_SETTING.NO}
                                onChange={e => handleChange(OVERVIEW_EDIT_TYPES.AUTO_REFUND, e.target.value)}
                            >
                                {Object.values(AUTO_REFUND_SETTING).map((val, idx) => (
                                    <option key={idx} value={val}>{val}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="overview-border p-3 mt-3">
                        <div className="dflex1 align-items-center mb-3">
                            <div className="fs12 fs500">Escrow Pay Account</div>
                            {/* {!escrow_pay_user_id && (
                                <div
                                    className='w-50 bgclr7 fs12 fw500 br4 px-2 py-2 text-center'
                                    style={{ cursor: 'pointer' }}
                                    onClick={createEscrowAccount}
                                >
                                    <img
                                        src='../../../../../assets/CE.svg'
                                        alt='' style={{ width: '15px' }} className='mr-2'
                                    />
                                    Create Escrow
                                </div>
                            )} */}
                        </div>
                        <div className="dflex1 p-2 br4">
                            <div className="w-50 fs10 fw300">User ID</div>
                            <div className="w-50 fs10 fw300 bgclr2 disable-input">{escrow_pay_user_id}</div>
                        </div>
                        <div className="dflex1 p-2 br4 mt-3">
                            <div className="w-50 fs10 fw300">Funds Available</div>
                            <div className="w-50 fs10 fw300 bgclr2 disable-input">{escrowBalance}</div>
                        </div>
                    </div>
                    <div className="overview-border p-3 mt-3">
                        <div className="fs12 fs500 mb-1">Company Relations</div>
                        <div className="dflex1 p-2 br4">
                            <div className="w-50 fs10 fw300">Capital Consultant</div>
                            <div className="w-50 fs10 fw300">{salesPartner?.agent_name}</div>
                            {/* <select
                                className='w-50 fs12'
                                value={sales_partner_id ? sales_partner_id : ""}
                                onChange={e => handleChange("sales_partner_id", e.target.value)}
                            >
                                <option value="" disabled>Select Sales Lead</option>
                                {salesPartners.map((p, idx) => (
                                    <option key={idx} value={p._id}>{p.sales_partner_name}</option>
                                ))}
                            </select> */}
                        </div>
                        <div className="dflex1 p-2 br4 mt-1">
                            <div className="w-50 fs10 fw300">Data Specialist</div>
                            <div className="w-50 fs10 fw300">{dataSpecialist?.agent_name}</div>
                        </div>
                        <div className="dflex1 p-2 br4 mt-1">
                            <div className="w-50 fs10 fw300">Underwriter</div>
                            <select
                                className='w-50 fs12'
                                value={underwriter_id ? underwriter_id : ""}
                                onChange={e => handleChange(OVERVIEW_EDIT_TYPES.UNDERWRITER_ID, e.target.value)}
                            >
                                <option value="" disabled>Select Underwriter</option>
                                {underwriters.map((p, idx) => (
                                    <option key={idx} value={p._id}>{p.agent_name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <AddNewModal
                investee_organization_id={investeeOrgId}
                isDisabled={true}
            />
        </div>

    )
}
